<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            text="Nova Forma de pagamento"
            type="primario"
            @click="novaFormaDePagameto"
          />
        </FormButtonGroup>
      </template>

      <DataTable
        class="formaPagamentoBody"
        :headers="headers"
        :endpoint="endpoint"
        sortBy="description"
        :sortDesc="false"
        ref="datatable"
        @click="onClick"
        search
      >
        <template v-slot:forma_default="{ row, col }">
          <FormCheckbox @change="toggleDefault(row)" :value="col" />
        </template>

        <template v-slot:idformapagto="{ row, col }">
          <div class="opcoes">
            <FormButtonMenu
              icon
              :options="tableOptions"
              :loading="loadingRow[row.idcadastroextraproduto]"
              @click="op => opcoesFormaDePagamento(row, op)"
            >
              <v-icon size="15">fas fa-ellipsis-v</v-icon>
            </FormButtonMenu>
          </div>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import MarketingModules from '@/components/MarketingModules'
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  FORMA_PAGAMENTO_DEFAULT,
  FORMA_PAGAMENTO_REMOVE
} from '@/store/actions/formaPagamento'

export default {
  name: 'FormaDePagamentoTable',
  components: {
    MarketingModules,
    DataTable,
    Panel,
    FormButton,
    FormButtonGroup,
    FormCheckbox,
    FormButtonMenu
  },
  data: () => ({
    headers: [
      { text: 'Padrão', value: 'forma_default', clickable: false },
      { text: 'Descrição', value: 'description' },
      {
        text: 'ID Forma de pagamento',
        value: 'id_payment_method_erp',
        align: 'center'
      },
      {
        text: 'ID Forma de cobrança',
        value: 'id_cobranca_erp',
        align: 'center'
      },
      { text: 'Fator preço', value: 'price_factor', align: 'center' },
      { text: 'Ordenação', value: 'payment_method_order', align: 'center' },
      {
        text: 'Opções',
        value: 'idformapagto',
        align: 'center',
        clickable: false
      }
    ],
    loadingRow: {},
    tableOptions: [
      { text: 'Editar', value: 'editar' },
      { text: 'Deletar', value: 'deletar' }
    ],
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  created() {
    this.loadingRow = {}
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/payment-method/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    async toggleDefault(paymentMethod) {
      if (!paymentMethod) return

      const { idformapagto, forma_default } = paymentMethod

      try {
        const data = await this.$store.dispatch(FORMA_PAGAMENTO_DEFAULT, {
          idfornecedor: this.getFornecedorId,
          idformapagto: idformapagto,
          forma_default: !forma_default
        })

        if (!data) {
          this.$vueOnToast.pop(
            'warning',
            'Não foi possível alterar a forma de pagamento.'
          )
        } else {
          this.$vueOnToast.pop(
            'success',
            'Forma de pagamento alterada com sucesso.'
          )
        }
      } catch (error) {
        if (error && error.responseJSON && error.responseJSON.msg) {
          this.$vueOnToast.pop('warning', error.responseJSON.msg)
        } else {
          this.sendToastNotification(
            'warning',
            'Não foi possível alterar a forma de pagamento.'
          )
        }
      }
    },
    novaFormaDePagameto() {
      this.$router.push('forma-pagamento/novo')
    },
    opcoesFormaDePagamento(row, op) {
      const acao = {
        editar: () => {
          const { idgateway, idformapagto } = row
          this.$router.push(`forma-pagamento/${idgateway}/${idformapagto}`)
        },

        deletar: () => {
          const { idformapagto, idempresa } = row

          this.loadingRow = {
            ...this.loadingRow,
            [idformapagto]: true
          }

          this.$swal
            .fire({
              title: `Deseja realmente remover esta forma de pagamento?`,
              text: 'Essa ação é irreversível',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            })
            .then(async result => {
              try {
                if (result.value) {
                  const data = await this.$store.dispatch(
                    FORMA_PAGAMENTO_REMOVE,
                    {
                      idfornecedor: this.getFornecedorId,
                      idformapagto: idformapagto,
                      idempresa: idempresa
                    }
                  )

                  this.loadingRow = {
                    ...this.loadingRow,
                    [idformapagto]: true
                  }

                  if (!data) {
                    this.$vueOnToast.pop(
                      'error',
                      'Não foi possível remover a forma de pagamento.'
                    )
                  } else {
                    this.$vueOnToast.pop(
                      'success',
                      'Forma de pagamento removida.'
                    )

                    this.$refs.datatable.reload()
                  }
                }
              } catch (error) {
                if (error.msg) {
                  this.$vueOnToast.pop('warning', error.msg)
                }

                this.loadingRow = {
                  ...this.loadingRow,
                  [idformapagto]: true
                }

                this.$vueOnToast.pop(
                  'error',
                  'Erro ao tentar remover forma de pagamento'
                )
              }
            })
        }
      }

      acao[op.value]()
    },
    onClick(item) {
      const { idgateway, idformapagto } = item
      this.$router.push(`forma-pagamento/${idgateway}/${idformapagto}`)
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/fornecedor/cadastro/forma-pagamento') {
        this.$refs.datatable.reload()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.opcoes i {
  padding: 8px;
  color: #505050;
}
</style>
